import http from "./http";
import Service from "../Service";

/**
 * @type AuthenticationService
 */
export default class AuthenticationService extends Service {

    authenticate(credentials) {
		return http.post(`auth`, credentials, {
			headers: this.getHeaders(),
            timeout: 600000
		});
	}

}
