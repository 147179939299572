import http from "./http";
import Service from "../Service";

/**
 * @type QuotationService
 */
export default class CredentialService extends Service {

	send_password(credential) {
		return http.get(`credentials/password`, {
			headers: this.getHeaders(),
			params: { credential }
		});
	}

}
