import http from "../Http";
import Service from "../Service";

export default class AuthService extends Service {
    authenticate(credentials) {
		return http.post(`auth`, credentials, {
			headers: this.getHeaders(),
            timeout: 600000
		});
	}
}